
import { Options, Vue } from 'vue-class-component';
import { RawMaterialClient } from '@/services/Services';
import EditModal from '../modals/editModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class RawMaterial extends Vue {

    items: OM.RawMaterialConfigurationVM[] = [];
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        RawMaterialClient.getAllConfigurations()
        .then(x => {
            this.items = x;
            this.loaded = true;
        })
    }

    editConfiguration(item: OM.RawMaterialConfigurationVM) {
        if(!item)
            item = new OM.RawMaterialConfigurationVM();

        this.$opModal.show(EditModal, {
            model: item,
            callback: (updatedModel: OM.RawMaterialConfigurationVM) => {
                RawMaterialClient.editConfiguration(updatedModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
                .catch(y => {
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("Error", "Error"),
                        subtitle: y.Message,
                        deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            }
        })
    }

}
